/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import DataTable from 'components/atoms/DataTable'
import { TableWrapper } from 'components/atoms/TableWrapper'
import { confirm } from 'components/molecules/Confirmation'
import * as generalHelper from 'helpers/generalHelper'
import { useOrdersTable } from 'hooks/useOrdersTable'
import moment from 'moment'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import {
  useAddToBillingQueueMutation,
  useGetLabOrdersQuery,
  useRemoveFromBillingQueueMutation,
  useSubmitBillingMutation,
  useGetRecentLabOrdersQuery,
} from 'store/services/labOrder'
import { useGetLabPhysiciansQuery } from 'store/services/labPhysician'
import { useGetLabOfficesQuery } from 'store/services/office'
import { useDebounce } from 'use-debounce'

import DashboardContent from '../atoms/DashboardContent'
import { COMPOUND_BUCKET_MAP, COMPOUND_BUCKETS } from '../constants/orderStatus'
import routes from '../constants/routes'
import { AdvancedSearch } from './AdvancedSearch'
import { bucketColumnDefaults } from './columnConfig'
import OrderTableColumns from './OrderTableColumns'

const StyledTableWrapper = styled(TableWrapper)`
  thead {
    z-index: 1;
  }
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.2rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

export type SearchParams = {
  orderId?: string
  officeId?: number
  patientFirstName?: string
  patientLastName?: string
  patientDob?: string
  physicianIds?: string
  physicianName?: string
  verified?: boolean
  aobVerified?: boolean
  hasUploads?: boolean
}

const initialSearch: SearchParams = {
  officeId: undefined,
  orderId: '',
  patientFirstName: '',
  patientLastName: '',
  patientDob: '',
  physicianIds: '',
  physicianName: '',
  verified: false,
  aobVerified: false,
  hasUploads: false,
}

const Order = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState<SearchParams>(initialSearch)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const clearSearch = () => setSearch(initialSearch)
  const [debouncedSearch] = useDebounce(search, 1000)
  const tableHelpers = useOrdersTable(debouncedSearch, clearSearch)
  const bucketFilter = COMPOUND_BUCKETS.includes(tableHelpers.bucket)
    ? COMPOUND_BUCKET_MAP[tableHelpers.bucket]
    : tableHelpers.bucket

  const { data: physicians } = useGetLabPhysiciansQuery({
    name: search?.physicianName,
  })
  const { data: offices } = useGetLabOfficesQuery({})
  const [addToBillingQueue] = useAddToBillingQueueMutation()
  const [removeFromBillingQueue] = useRemoveFromBillingQueueMutation()
  const [submitBilling] = useSubmitBillingMutation()
  const patientDobText = debouncedSearch?.patientDob?.replaceAll(' ', '')
  const patientDob = patientDobText?.length === 10 ? moment(patientDobText) : null
  const orderFetchFn =
    tableHelpers.bucket === 'RECENT' ? useGetRecentLabOrdersQuery : useGetLabOrdersQuery

  const {
    data: orders,
    isLoading,
    refetch: refetchOrders,
  } = orderFetchFn(
    {
      orderId: search.orderId,
      officeIds: search.officeId ? [search.officeId] : undefined,
      types: tableHelpers.testType?.replace(/_/g, ',') || 'POX',
      buckets: bucketFilter === 'RECENT' ? undefined : bucketFilter,
      page: tableHelpers.currentPage,
      size: tableHelpers.pageSize,
      sortField: tableHelpers.sortField,
      sortOrder:
        tableHelpers.sortField === 'deviceAssignedAt'
          ? tableHelpers.sortOrder === 'asc'
            ? 'desc'
            : 'asc'
          : tableHelpers?.sortOrder,
      ...generalHelper.cleanObj(search, true),
      ...(debouncedSearch.patientDob && {
        patientDob: patientDob?.isValid() ? patientDob?.format('YYYY-MM-DD') : undefined,
      }),
    },
    {
      pollingInterval: 60000,
      refetchOnFocus: true,
    },
  )

  const changeSearch = (field: string, value: string | boolean) => {
    setSearch((p) => ({ ...p, [field]: value }))
  }

  return (
    <div>
      <DashboardContent
        title="Orders"
        icon="fas fa-folder-open"
        content={
          <div className="card card-primary mt-1">
            <StyledTableWrapper
              className="card-body row"
              css={{
                overflowY: !orders?.items?.length || orders?.items?.length < 10 ? 'unset' : 'auto',
              }}>
              <AdvancedSearch
                search={search}
                changeSearch={changeSearch}
                clearSearch={clearSearch}
                physicians={physicians}
                offices={offices}
                tableHelpers={tableHelpers}
                showExport={tableHelpers.bucket === 'INS_VER'}
              />
              {tableHelpers.bucket === 'BILLING_REVIEW' && (
                <Button
                  variant="primary"
                  disabled={!selectedIds.length}
                  onClick={async () => {
                    try {
                      setSelectedIds([])
                      await addToBillingQueue({ orders: selectedIds }).unwrap()
                    } catch (e) {
                      console.log(e)
                      if (e?.data?.message) {
                        confirm('', {
                          title: 'Error',
                          description: e?.data?.message,
                          yesAction: 'OK',
                          noAction: undefined,
                        })
                      }
                    }
                  }}
                  className="ml-2 mb-4">
                  Send To CureMD
                </Button>
              )}
              {(tableHelpers.bucket === 'BILLING_QUEUED' ||
                tableHelpers.bucket === 'BILLING_FAILED') && (
                <div className="d-flex mb-3 mt-2 ml-3">
                  <Button
                    variant="primary"
                    disabled={!selectedIds.length}
                    onClick={async () => {
                      try {
                        await removeFromBillingQueue({ orders: selectedIds }).unwrap()
                        setSelectedIds([])
                      } catch (e) {
                        if (e?.data?.message) {
                          confirm('', {
                            title: 'Error',
                            description: e?.data?.message,
                            yesAction: 'OK',
                            noAction: undefined,
                          })
                        }
                      }
                    }}
                    className="mr-3">
                    Remove from CureMD Queue
                  </Button>
                  <Button
                    variant="primary"
                    onClick={async () => {
                      try {
                        await confirm('', {
                          title: 'Are you sure?',
                          description: `This will ${tableHelpers.bucket === 'BILLING_QUEUED' ? 'submit' : 're-submit'} ${selectedIds.length ? `all selected (${selectedIds.length})` : `*ALL* ${tableHelpers.bucket === 'BILLING_QUEUED' ? 'pending' : 'failed'} (${orders?.items?.length})`} orders to CureMD.`,
                          yesAction: 'Submit',
                          noAction: 'Cancel',
                        })
                        await submitBilling({ orders: selectedIds }).unwrap()
                        setSelectedIds([])
                      } catch {}
                    }}>
                    {tableHelpers.bucket === 'BILLING_FAILED' ? 'Re-Submit' : 'Submit'} To CureMD
                  </Button>
                </div>
              )}
              <DataTable
                data={orders}
                status={isLoading}
                tableColumns={OrderTableColumns({
                  testType: tableHelpers.testType,
                  bucket: tableHelpers.bucket,
                  setSelectedIds,
                  selectedIds,
                  stats: orders?.stats,
                })}
                select={bucketColumnDefaults[tableHelpers.testType === 'HST' ? 'HST' : 'POXCOX']?.[
                  tableHelpers.bucket
                ]?.some((c) => c === 'select')}
                pageSize={tableHelpers.pageSize}
                setCurrentPage={tableHelpers.setCurrentPage}
                setSortOptions={tableHelpers.setSortOptions}
                // onRowClick={(row) => {
                //   navigate(
                //     `${routes.index}${routes.order.edit}${row.original.id}?bucket=${tableHelpers.bucket}${
                //       tableHelpers.actionItems ? '&actionItems=1' : ''
                //     }${tableHelpers.testType ? `&testType=${tableHelpers.testType}` : ''}`,
                //   )
                // }}
              />
            </StyledTableWrapper>
          </div>
        }
        breadcrumb={[{ label: 'Home', to: `${routes.index}` }]}
        subtitle={''}
      />
    </div>
  )
}

export default Order
