/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { skipToken } from '@reduxjs/toolkit/query/react'

import NightOwlIcon from 'assets/img/nightowl-icon.svg'
import Button from 'components/atoms/Button'
import { HorizontalStepper } from 'components/atoms/HorizontalStepper'
import NavigateWarning from 'components/atoms/NavigateWarning'
import Upload from 'components/atoms/Upload'
import PatientUpsert from 'components/dme/orderEdit/views/forms/PatientUpsert'
import OrderTestModal from 'components/dme/orderEdit/views/header/OrderTest.modal'
import { CancelDialog } from 'components/molecules/CancelDialog'
import { confirm } from 'components/molecules/Confirmation'
import { ReportListing } from 'components/organisms/reports/ReportListing'
import * as api from 'helpers/api'
import orderStateHelpers, { HST_STEPS, PSG_STEPS } from 'helpers/orderStateHelpers'
import { useAppDispatch } from 'hooks/redux'
import { useCallbackPrompt } from 'hooks/useCallbackPrompt'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import BsButton from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  useAddLabFileMutation,
  useAddOrderDiagnosisCodeMutation,
  useAddToBillingQueueMutation,
  useCancelOrderMutation,
  useChangeInLabStepMutation,
  useCreateMobileInviteMutation,
  useFinishOrderVerificationMutation,
  useGetLabOrderQuery,
  useGetLabOrdersQuery,
  useInitiateTestSmarterFulfillmentMutation,
  useLabAobSignRequestMutation,
  useRemoveLabFileMutation,
  useRemoveOrderDiagnosisCodeMutation,
  useRestoreOrderMutation,
  useRevokeVerificationMutation,
  useSendPatientDataToCureMdMutation,
  useSetLabOrderPayerInfoMutation,
  useStartOrderVerificationMutation,
  useUnverifyOrderMutation,
  useUpdateLabOrderPayerMutation,
  useVerifyOrderMutation,
} from 'store/services/labOrder'
import {
  useGetLabMessagesQuery,
  useSendLabMessageMutation,
  useUpdateMessageReadStatusMutation,
} from 'store/services/messages'
import {
  useDeleteNoteMutation,
  useGetLabNotesQuery,
  useSendLabNoteMutation,
} from 'store/services/notes'
import { setLoader } from 'store/slice/loaderSlice'
import { setModal } from 'store/slice/modalSlice'
import { Payer, UploadType, VerificationSection } from 'store/types'
import { useUpdateOrderMutation } from '../../../store/services/dmeOrder'
import Toast from '../../atoms/Toast'
import { BrightreeLogs } from '../../organisms/log/BrightreeLogs'
import DashboardContent from '../atoms/DashboardContent'
import routes from '../constants/routes'
import { DiagnosisCodes } from './DiagnosisCodes'
import { DocumentPanel } from './DocumentPanel'
import { FailValidationDialog } from './FailValidationDialog'
import { getDefaultPanelStates, Panels } from './helpers'
import { LabInsuranceForm } from './InsuranceForm'
import { HstDeviceModal } from './NightOwl/HstDeviceModal'
import { PatientHeaderTwo } from './NightOwl/PatientHeaderTwo'
import { PhysicianSection } from './PhysicianSection'
import { HstWorkflow, PsgWorkflow } from './PsgHstWorkflow'
import { DisregardReportModal } from './report/DisregardReportModal'
import { UpdateReportModal } from './report/UpdateReportModal'
import SessionEditor from './sessions/SessionEditor'
import { TabControl } from './TabControl'
import TestSmarterFulfillment from './TestSmarterFulfillment/TestSmarterFulfillment'
import { TooltipWrapper } from './TooltipWrapper'
import { VerifyStatusButton } from './VerifyStatusButton'

const StyledDropdownItem = styled(Dropdown.Item)`
  padding: 0.55rem 1rem;
`

const logisticsBuckets = [
  'NO_NEW',
  'NO_FOLLOW_UP',
  'NO_NEED_TO_SHIP',
  'NO_READY_TO_SHIP',
  'NO_SHIPPED',
  'NO_DELIVERED',
  'PENDING_RETURN',
]

const documentSortOrder = ['RX', 'AOB', 'OFFICE_NOTES'].reduce(
  (acc, type, idx) => {
    acc[type] = idx
    return acc
  },
  {} as Record<string, number>,
)

export type VerifyType = '' | 'failed' | 'pending' | 'verified'
export type OrderVerifyStatus = {
  PATIENT: VerifyType
  PHYSICIAN: VerifyType
  PAYER: VerifyType
  PAYER_2: VerifyType
  PAYER_3: VerifyType
  RX: VerifyType
  AOB: VerifyType
  OFFICE_NOTES: VerifyType
}

const StyledAccordion = styled(Accordion)`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;

  .accordion-button {
    background: white;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 40px;

    > div {
      margin-left: 20px;
      color: #444;
    }
  }

  .accordion-button::after {
    left: 10px;
    top: 14px;
    position: absolute;
    filter: grayscale(1);
  }
`

const StyledColumn = styled.div`
  overflow-y: auto;
  height: calc(100vh - 121px);
`

const verifyStatusMap = new Map()
verifyStatusMap.set('PENDING_REVIEW', 'pending')
verifyStatusMap.set('PROVIDED', 'pending')
verifyStatusMap.set('ON_REVIEW', 'pending')
verifyStatusMap.set('PENDING', 'pending')
verifyStatusMap.set('FIXED', 'pending')
verifyStatusMap.set('DONE', 'verified')
verifyStatusMap.set('FAILED', 'failed')
verifyStatusMap.set('WARNING', 'failed')
verifyStatusMap.set(undefined, '')

const OrderEdit = () => {
  const { orderId } = useParams()
  const [urlSearch] = useSearchParams()
  const orderIdNum = Number(orderId)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [uploadType, setUploadType] = useState('')
  const [showFailDialog, setShowFailDialog] = useState<VerificationSection>()
  const [patientFormDirty, setPatientFormDirty] = useState(false)
  const [verification, setVerification] = useState<OrderVerifyStatus>({
    PATIENT: '',
    PHYSICIAN: '',
    PAYER: '',
    PAYER_2: '',
    PAYER_3: '',
    RX: '',
    AOB: '',
    OFFICE_NOTES: '',
  })
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false)
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false)
  const [disregardModalOpen, setDisregardModalOpen] = useState<boolean>(false)
  const [disregardReportId, setDisregardReportId] = useState<number | null>(null)
  const [disregardFaxNumber, setDisregardFaxNumber] = useState<string>('')
  const [panelStates, setPanelStates] = useState<boolean[]>([])
  const [insuranceFormDirty, setInsuranceFormDirty] = useState(false)
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog)
  const patientAcc = useRef()
  const verificiationInitialized = useRef<boolean>(false)
  const uploadRef = useRef<HTMLInputElement>()
  const [addDiagnosisCode] = useAddOrderDiagnosisCodeMutation()
  const [removeDiagnosisCode] = useRemoveOrderDiagnosisCodeMutation()
  const [startVerification] = useStartOrderVerificationMutation()
  const [finishVerification] = useFinishOrderVerificationMutation()
  const [updateOrderPayer] = useUpdateLabOrderPayerMutation()
  const [setOrderPayerInfo] = useSetLabOrderPayerInfoMutation()
  const [addFile] = useAddLabFileMutation()
  const [removeLabFile] = useRemoveLabFileMutation()
  const [verifyOrderSection] = useVerifyOrderMutation()
  const [unverifyOrderSection] = useUnverifyOrderMutation()
  const [revokeVerification] = useRevokeVerificationMutation()
  const [sendAobSignRequest] = useLabAobSignRequestMutation()
  const [cancelOrder] = useCancelOrderMutation()
  const [restoreOrder] = useRestoreOrderMutation()
  const [initiateTestSmarterFulfillment] = useInitiateTestSmarterFulfillmentMutation()
  const [bottomTab, setBottomTab] = useState('notes')
  const [updateMessageReadStatus] = useUpdateMessageReadStatusMutation()
  const [deleteNote] = useDeleteNoteMutation()
  const [changeInLabStep] = useChangeInLabStepMutation()
  const [sendPatientDataToCureMd] = useSendPatientDataToCureMdMutation()
  const [sendLabMessage] = useSendLabMessageMutation()
  const [sendLabNote] = useSendLabNoteMutation()
  const [addToBillingQueue] = useAddToBillingQueueMutation()
  const [createMobileInvite] = useCreateMobileInviteMutation()
  const [reportsSelections, setReportsSelections] = useState<number[]>([])
  const { data: messages } = useGetLabMessagesQuery(orderId ? { orderId } : skipToken, {
    refetchOnFocus: true,
  })
  const { data: notes } = useGetLabNotesQuery(orderId ? { orderId } : skipToken, {
    refetchOnFocus: true,
  })

  const addMessage = async (message: string) => {
    if (!orderId) return
    await sendLabMessage({ orderId: Number(orderId), text: message, context: '' })
  }
  const addNote = async (note: string, type?: string) => {
    if (!orderId) return
    await sendLabNote({
      orderId: Number(orderId),
      text: note,
      type,
    })
  }
  const [deviceModalOpen, setDeviceModalOpen] = useState(false)
  const bucket = location.search
    ?.replace('?', '')
    ?.split('&')
    ?.find((i) => i.includes('bucket'))
    ?.split('=')?.[1]
  // console.log('>>>>>>>>>>>', verification, bucket, panelStates)

  const { data: order, refetch: refetchOrder, error } = useGetLabOrderQuery(Number(orderId))
  const { data: patientOrders } = useGetLabOrdersQuery(
    order?.patient?.id ? { patientId: order?.patient?.id } : skipToken,
  )
  const [fulfillmentPageOpen, setFulfillmentPageOpen] = useState<boolean>()
  const orderState = orderStateHelpers(order)
  const showVerifyButtons = orderState?.hasVerificationStarted || orderState?.isVerificationDone
  const disableInsuranceVerification =
    (order?.testType === 'HST' || order?.testType === 'PSG') &&
    (order?.copay === '' || isNaN(Number(order?.copay)))
  const canEmailAob = !!order?.patient?.email
  const canTextAob = !!order?.patient?.mobilePhone
  const hasQueuedReports =
    order?.reports && Object.values(order.reports).some((r) => r === 'QUEUED')
  const showSessionEditor = order?.tasks?.some(
    (t) => t.namespace === 'DEVICE_DATA_PROBLEM' && !t.resolved,
  )
  const [updateOrder] = useUpdateOrderMutation()

  const totalPatientOrders = patientOrders?.totalItems
  // console.log(orderState)

  const handleVerificationChange = (section: VerificationSection) => (v: VerifyType) => {
    if (section === 'PAPERWORK') {
      section = 'AOB'
    }
    if (v === 'failed') {
      setShowFailDialog(section)
    } else if (v === 'verified') {
      passOrderSection(section)
      setVerification((p) => ({ ...p, [section]: v }))
      if (VerificationSection.PATIENT === section) {
        setPanelStates((p) => p.map((i, idx) => (idx === Panels.PATIENT ? !i : i)))
      } else if (VerificationSection.PHYSICIAN === section) {
        setPanelStates((p) => p.map((i, idx) => (idx === Panels.PHYSICIAN ? !i : i)))
      } else if (
        [
          VerificationSection.PAYER,
          VerificationSection.PAYER_2,
          VerificationSection.PAYER_3,
        ].includes(section) &&
        (verification.PAYER !== 'pending' || section === VerificationSection.PAYER) &&
        (verification.PAYER_2 !== 'pending' || section === VerificationSection.PAYER_2) &&
        (verification.PAYER_3 !== 'pending' || section === VerificationSection.PAYER_3)
      ) {
        setPanelStates((p) => p.map((i, idx) => (idx === Panels.INSURANCE ? !i : i)))
      }
    } else {
      revertOrderSection(section)
      setVerification((p) => ({ ...p, [section]: v }))
    }
  }

  const navigateToList = () => {
    const bucket = urlSearch.get('bucket')
    const actionItems = urlSearch.get('actionItems')
    setTimeout(
      () =>
        navigate(
          `${routes.index}${routes.order.root}?bucket=${bucket}${
            actionItems
              ? '&actionItems=1&testType=POX_COX_HST'
              : order?.testType === 'HST'
                ? '&testType=HST'
                : order?.testType === 'PSG'
                  ? '&testType=PSG'
                  : '&testType=POX_COX'
          }`,
        ),
      1000,
    )
  }

  const startOrderVerification = () => {
    if (orderId) {
      void startVerification({ orderId })
    }
  }
  const finishOrderVerification = async () => {
    if (orderState?.psgStep === 0) {
      // setPsgStep(1)
    }

    if (orderId) {
      await finishVerification({ orderId })
      // navigateToList()
    }
  }
  const reopenOrder = async () => {
    try {
      if (hasQueuedReports) {
        await confirm('', {
          title: 'Are you sure?',
          description: 'There are reports queued for rendering.  This will cancel their creation?',
          noAction: 'Cancel',
          yesAction: 'Re-open order and cancel queued reports',
        })
      }
      revokeVerification({ orderId: orderIdNum })
    } catch {}
  }

  const addCode = (code: string) => orderId && addDiagnosisCode({ orderId, code })
  const removeCode = (code: string) => orderId && removeDiagnosisCode({ orderId, code })
  const updatePayers = (payers: Payer[]) => {
    if (orderId) {
      return updateOrderPayer({ orderId, payers })
    }
  }

  const removeFile = (fileKey: string) =>
    orderId &&
    fileKey &&
    confirm('file').then(() => {
      removeLabFile({ orderId: orderIdNum, fileKey })
    })
  const passOrderSection = async (section: VerificationSection) => {
    try {
      await verifyOrderSection({ orderId: orderIdNum, section, result: 'GOOD' }).unwrap()
    } catch (err) {
      console.log(err)
      toast.error(`Unable to verify section. ${err.data.message}`)
    }
  }
  const failOrderSection = async (section: VerificationSection, message?: string) => {
    try {
      await verifyOrderSection({ orderId: orderIdNum, section, result: 'BAD', message }).unwrap()
    } catch (e) {
      toast.error(`Unable to fail section. ${err.data.message}`)
    }
  }
  const revertOrderSection = (section: VerificationSection) =>
    unverifyOrderSection({ orderId: orderIdNum, section })

  const startUpload = (type: string) => () => {
    setUploadType(type)
    uploadRef.current.click()
  }

  const handleUpload = async (acceptedFiles: File[]) => {
    dispatch(setLoader(true))
    const [file] = acceptedFiles
    console.log(file)
    if (!file?.name) return

    const payload = {
      orderId,
      uploadType,
    }
    const { url, key } = await api.get(`/lab/upload-url`, {
      ...payload,
      fileName: file.name,
    })

    console.log(url, key)
    await api.s3Put(url, file)
    await addFile({ ...payload, fileKey: key, fileName: file.name })

    dispatch(setLoader(false))
  }

  const aobSignRequest = (method: 'sms' | 'email') => async () => {
    try {
      await sendAobSignRequest({
        orderId: Number(orderId),
        method,
        ...(method === 'sms'
          ? { phone: order?.patient?.mobilePhone }
          : { email: order?.patient?.email }),
      })
      toast.success('AOB request successfully sent.')
    } catch (err) {
      toast.error('Could not send AOB request.')
    }
  }

  useEffect(() => {
    if (!order) return

    const done = orderState?.isVerificationDone ? 'verified' : null
    const initialVerificationState = {
      PATIENT: verifyStatusMap.get(order?.progress?.PATIENT),
      PHYSICIAN: verifyStatusMap.get(order?.progress?.PHYSICIAN),
      PAYER:
        verifyStatusMap.get(order?.state?.VERIFICATION?.RESULT?.PAYER) ||
        verifyStatusMap.get(order?.state?.VERIFICATION),
      PAYER_2:
        verifyStatusMap.get(order?.state?.VERIFICATION?.RESULT?.PAYER_2) ||
        verifyStatusMap.get(order?.state?.VERIFICATION),
      PAYER_3:
        verifyStatusMap.get(order?.state?.VERIFICATION?.RESULT?.PAYER_3) ||
        verifyStatusMap.get(order?.state?.VERIFICATION),
      RX: done || verifyStatusMap.get(order?.state?.VERIFICATION?.RESULT?.RX),
      AOB: done || verifyStatusMap.get(order?.state?.VERIFICATION?.RESULT?.AOB),
      OFFICE_NOTES: done || verifyStatusMap.get(order?.state?.VERIFICATION?.RESULT?.OFFICE_NOTES),
      PAPERWORK: done || verifyStatusMap.get(order?.state?.VERIFICATION?.RESULT?.AOB),
    }
    setVerification(initialVerificationState)
    verificiationInitialized.current = true
    if (!panelStates?.length) {
      setPanelStates(getDefaultPanelStates(initialVerificationState, bucket))
    }
    if (order.thirdPartyDevice?.deviceType !== 'TESTSMARTER' && fulfillmentPageOpen) {
      setFulfillmentPageOpen(false)
    } else if (
      order.thirdPartyDevice?.deviceType === 'TESTSMARTER' &&
      !fulfillmentPageOpen &&
      logisticsBuckets.includes(bucket || '')
    ) {
      setFulfillmentPageOpen(true)
    }
  }, [order])

  useEffect(() => {
    if (error?.status === 404) {
      toast.error('The order you are looking for does not exist.')
    }
  }, [error])

  useEffect(() => {
    const newVal = !!orderState?.isUnderVerification && orderState.hasItemsToVerify
    if (!!showDialog !== newVal) {
      setShowDialog(newVal)
    }
  }, [orderState?.isUnderVerification, orderState.hasItemsToVerify])

  const uploadTypes = [...new Set([...Object.values(order?.files || {}).map((f) => f.uploadType)])]
    .filter((i) => ['RX', 'AOB', 'OFFICE_NOTES', 'BILLING_DOC', 'OTHER', 'PAPERWORK'].includes(i))
    .sort((a, b) => documentSortOrder[a] - documentSortOrder[b])

  const rxUploaded = uploadTypes.includes('RX')
  const aobUploaded = uploadTypes.includes('AOB')
  const questionnaireUploaded = uploadTypes.includes('PAPERWORK')

  const openOrderTestModal = () => {
    dispatch(
      setModal({
        id: 'order_test_modal',
        patient_id: null,
        device_type_id: null,
      }),
    )
  }

  const poxHeaderContent = () =>
    !fulfillmentPageOpen ? (
      !orderState?.isVerificationDone ? (
        orderState?.isUnderVerification ? (
          <BsButton variant="primary" onClick={finishOrderVerification}>
            Complete Review
          </BsButton>
        ) : (
          <BsButton
            variant="warning"
            onClick={startOrderVerification}
            disabled={!orderState.canOrderReviewStart}>
            Start Order Review
          </BsButton>
        )
      ) : (
        orderState?.isVerificationDone && (
          <BsButton variant="warning" onClick={reopenOrder}>
            Re-Open Order
          </BsButton>
        )
      )
    ) : (
      <></>
    )

  const hstHeaderContent = () =>
    orderState.hstStep === HST_STEPS.VERIFICATION ? (
      orderState?.isUnderVerification ? (
        <BsButton variant="primary" onClick={finishOrderVerification}>
          Complete Review
        </BsButton>
      ) : (
        <BsButton
          variant="warning"
          onClick={startOrderVerification}
          disabled={!orderState.canOrderReviewStart}>
          Start Order Review
        </BsButton>
      )
    ) : (
      <BsButton variant="outline-dark" onClick={reopenOrder}>
        Re-Open Order
      </BsButton>
    )

  return (
    <div
      css={{
        '.subtitle': {
          backgroundColor: '#f4f6f9 !important',
          zIndex: 1040,
          position: 'sticky',
          top: 0,
          '& .bg-transparent, .bg-white, .dashboard_header_subtitle': {
            backgroundColor: 'transparent !important',
          },
        },
      }}>
      <HstDeviceModal
        isLab
        order={order}
        open={deviceModalOpen}
        onClose={() => setDeviceModalOpen(false)}
      />
      <OrderTestModal modalId="order_test_modal" title="Order Test" order={order} />
      {updateModalOpen && (
        <UpdateReportModal
          order={order}
          open={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
        />
      )}
      {disregardModalOpen && (
        <DisregardReportModal
          order={order}
          open={disregardModalOpen}
          faxNumber={disregardFaxNumber}
          disregardReportId={disregardReportId}
          setFaxNumber={setDisregardFaxNumber}
          onClose={() => {
            setDisregardModalOpen(false)
            setDisregardReportId(null)
          }}
          reportsSelections={reportsSelections}
        />
      )}
      <DashboardContent
        title={
          order?.movedTo
            ? `This order was cancelled and moved to #${order.movedTo}`
            : `${order?.cancelled ? 'Cancelled' : ''} Order ${order?.sourceOrder ? `(source #${order.sourceOrder})` : ''}`
        }
        subtitle={
          <PatientHeaderTwo
            order={order}
            addNight={async (night) => {
              await updateOrder({
                id: order.id,
                testType: order?.testType,
                tests: [
                  ...order.tests,
                  {
                    oxygen: 0,
                    oxygenLpm: 0,
                    deviceType: '',
                    papType: '',
                    apapMax: 0,
                    apapMin: 0,
                    bipapE: 0,
                    bipapI: 0,
                    cpap: 0,
                    ventilatorType: '',
                    night,
                  },
                ],
              })
            }}
            createMobileInvite={createMobileInvite}
            onChangeDeviceClick={() => setDeviceModalOpen(true)}
            onEditTestConditionsClick={openOrderTestModal}
          />
        }
        subtitleClassName="subtitle"
        icon={`fas ${orderState?.isUnderVerification ? 'fa-lock' : 'fa-lock-open'}`}
        headerClassName={
          order?.cancelled
            ? order?.movedTo
              ? 'bg-secondary'
              : 'bg-danger'
            : orderState?.isUnderVerification
              ? 'bg-warning'
              : ''
        }
        headerCenterContent={
          order?.cancelled
            ? null
            : order?.testType === 'HST'
              ? hstHeaderContent()
              : poxHeaderContent()
        }
        content={
          order?.thirdPartyDevice?.deviceType !== 'TESTSMARTER' || !fulfillmentPageOpen ? (
            <div className="card-body row pt-0">
              <FailValidationDialog
                show={showFailDialog}
                onClose={() => setShowFailDialog(undefined)}
                onSubmit={(message: string) => {
                  if (showFailDialog) {
                    failOrderSection(showFailDialog, message)
                    setVerification((p) => ({ ...p, [showFailDialog]: 'failed' }))
                    setShowFailDialog(undefined)
                  }
                }}
              />
              <CancelDialog
                show={showCancelDialog}
                onClose={() => setShowCancelDialog(false)}
                onSubmit={async (message: string) => {
                  if (showCancelDialog) {
                    await cancelOrder({ orderId: Number(orderId) }).unwrap()
                    if (message)
                      await sendLabMessage({ orderId: Number(orderId), text: message, context: '' })
                    navigateToList()
                    setShowCancelDialog(false)
                  }
                }}
              />
              <NavigateWarning
                showDialog={showPrompt}
                content={
                  <div
                    css={css`
                      line-height: 1.3rem;
                    `}>
                    This order is under review and cannot be updated by DME users. Do you want to
                    navigate away without completing your review?
                  </div>
                }
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
              {showSessionEditor && (
                <SessionEditor
                  order={order}
                  title={<span>The following sessions require modification:</span>}
                />
              )}
              {!!order?.billingFailure?.length && (
                <div
                  className="w-100 mb-2 ml-2"
                  css={{
                    background: orderState.billingSucceeded ? 'white' : 'rgba(255, 0, 0, 0.1)',
                    borderRadius: '5px',
                    border: '0.6px solid rgb(200, 200, 200)',
                  }}>
                  <div className="container p-3">
                    <span css={{ fontSize: '16px' }}>
                      {orderState.billingSucceeded ? 'Previous' : ''} Billing Errors
                    </span>
                    <div
                      css={{
                        height: 200,
                        overflow: 'scroll',
                      }}>
                      {order.billingFailure.map((i) => (
                        <div className="container p-1">
                          {moment(i.timestamp).format('lll')} - {i.message}
                        </div>
                      ))}
                    </div>
                    {orderState.billingSucceeded && (
                      <div className="mt-3">
                        After these errors, a charge was successfully created for this order.
                      </div>
                    )}
                  </div>
                </div>
              )}
              {order &&
                (!!Object.values(order.reports ?? {})?.length ||
                  order.selectedSessions?.length) && (
                  <div
                    className="w-100 mb-2 ml-2"
                    css={{
                      background: 'white',
                      borderRadius: '5px',
                      border: '0.6px solid rgb(200, 200, 200)',
                    }}>
                    <ReportListing
                      order={order}
                      userType="lab"
                      openUpdateModal={() => setUpdateModalOpen(true)}
                      openDisregardModal={(id) => {
                        if (id) {
                          setDisregardReportId(id)
                        }

                        setDisregardModalOpen(true)
                      }}
                      setDisregardFaxNumber={setDisregardFaxNumber}
                      reportsSelections={reportsSelections}
                      setReportsSelections={setReportsSelections}
                      billingSucceeded={!!orderState.billingSucceeded}
                    />
                  </div>
                )}

              {order?.testType === 'HST' &&
                order?.state?.ORDER !== 'DONE' &&
                !Object.keys(order?.reports || {}).length && (
                  <div className="w-100 mb-2 ml-2" css={{ backgroundColor: 'white' }}>
                    <HorizontalStepper
                      steps={[
                        { label: 'Verification', id: 0 },
                        { label: 'Capture Patient Consent', id: 1 },
                        ...(order?.state?.HST?.REQUIRED?.PROCESS?.AUTHORIZATION !== 'NOT_REQUIRED'
                          ? [{ label: 'Pre Authorization', id: 2 }]
                          : []),
                        { label: 'Patient Scheduling', id: 3 },
                        { label: 'Device Shipping', id: 4 },
                        { label: 'Patient Testing', id: 5 },
                      ]}
                      activeStep={orderState.hstStep}
                      onStepClick={() => {}}
                    />
                  </div>
                )}

              {order?.testType === 'PSG' && order?.state?.ORDER !== 'DONE' && (
                <div className="w-100 mb-2 ml-2" css={{ backgroundColor: 'white' }}>
                  <HorizontalStepper
                    steps={[
                      { label: 'Verification', id: 0 },
                      { label: 'Capture Patient Consent', id: 1 },
                      { label: 'Pre Authorization', id: 2 },
                      { label: 'Patient Scheduling', id: 3 },
                    ]}
                    activeStep={orderState?.psgStep}
                    onStepClick={() => {}}
                  />
                  {verification.PATIENT === 'verified' &&
                    verification.PHYSICIAN === 'verified' &&
                    verification.RX === 'verified' &&
                    !orderState?.isUnderVerification && (
                      <div className={'d-flex justify-content-between px-4 pb-2'}>
                        <Button
                          className="btn btn-outline-primary"
                          label="< Previous Step"
                          onClick={async () => {
                            await changeInLabStep({ orderId: Number(orderId), type: 'prev' })
                          }}
                          disabled={orderState?.psgStep === PSG_STEPS.VERIFICATION}
                        />
                        <Button
                          className="btn btn-outline-primary"
                          label="Next Step >"
                          onClick={async () => {
                            await changeInLabStep({ orderId: Number(orderId), type: 'next' })
                          }}
                          disabled={orderState?.psgStep === PSG_STEPS.PATIENT_SCHEDULING}
                        />
                      </div>
                    )}
                </div>
              )}

              {orderState.showHstLogistics && order?.testType !== 'PSG' && (
                <>
                  <div
                    className="w-100 mb-1 ml-2 p-3"
                    css={{
                      background: 'white',
                      borderRadius: '5px',
                      border: '0.6px solid rgb(200, 200, 200)',
                    }}>
                    <HstWorkflow orderState={orderState} order={order} />
                  </div>
                  <hr className="w-100 ml-2" />
                </>
              )}

              {order?.testType === 'PSG' &&
                order?.bucket !== 'COMPLETED' &&
                orderState?.psgStep !== 0 && (
                  <>
                    <div
                      className="w-100 mb-1 ml-2 p-3"
                      css={{
                        background: 'white',
                        borderRadius: '5px',
                        border: '0.6px solid rgb(200, 200, 200)',
                      }}>
                      <PsgWorkflow step={orderState?.psgStep} order={order} />
                    </div>
                    <hr className="w-100 ml-2" />
                  </>
                )}

              <StyledColumn className="col-sm-12 col-md-6">
                <StyledAccordion
                  defaultActiveKey="0"
                  activeKey={panelStates[Panels.PATIENT] ? '0' : null}
                  onSelect={() =>
                    setPanelStates((p) => p.map((i, idx) => (idx === Panels.PATIENT ? !i : i)))
                  }>
                  <Accordion.Item eventKey="0">
                    <TooltipWrapper orderState={orderState}>
                      <Accordion.Button ref={patientAcc}>
                        <div>
                          Patient{' '}
                          {orderState?.psgStep === PSG_STEPS.PATIENT_SCHEDULING &&
                            order?.testType === 'PSG' && (
                              <BsButton
                                className="ml-2"
                                variant={'outline-primary'}
                                onClick={async (e) => {
                                  e.stopPropagation()

                                  confirm('', {
                                    title: 'Are you sure?',
                                    description:
                                      'This will sync patient and insurance data with CureMD.',
                                    yesAction: 'Yes, proceed',
                                    noAction: 'Cancel',
                                  })
                                    .then(async () => {
                                      try {
                                        await sendPatientDataToCureMd({ orderId: Number(orderId) })
                                        Toast({ type: 'success', label: 'Data sent to CureMD!' })
                                      } catch (err) {
                                        Toast({
                                          type: 'error',
                                          label: `Error sending data to CureMD.`,
                                        })
                                      }
                                    })
                                    .catch(() => {})
                                }}
                                css={{ cursor: 'default', padding: '0 10px' }}>
                                Sync Patient Data with CureMD
                              </BsButton>
                            )}
                        </div>
                        {showVerifyButtons && (
                          <VerifyStatusButton
                            disabled={!orderState?.isUnderVerification}
                            value={verification.PATIENT}
                            onChange={handleVerificationChange(VerificationSection.PATIENT)}
                          />
                        )}
                      </Accordion.Button>
                    </TooltipWrapper>
                    <Accordion.Collapse eventKey="0">
                      <div className="col-sm-12 p-3">
                        {order?.patient ? (
                          <>
                            {(order?.patient?.firstNameV1 ||
                              order?.patient?.lastNameV1 ||
                              order?.patient?.fullNameV1) && (
                              <div
                                className="col-9 py-2 pl-4"
                                css={{ background: '#e0ffe070', borderRadius: '5px' }}>
                                <div className="row">
                                  <div className="col-4 text-bold">V1 First Name</div>
                                  <div className="col-4 text-bold">V1 Last Name</div>
                                  <div className="col-4 text-bold">V1 Full Name</div>
                                </div>
                                <div className="row">
                                  <div className="col-4">{order?.patient?.firstNameV1}</div>
                                  <div className="col-4">{order?.patient?.lastNameV1}</div>
                                  <div className="col-4">{order?.patient?.fullNameV1}</div>
                                </div>
                              </div>
                            )}
                            <PatientUpsert
                              labUser
                              orderId={order.id}
                              modalView
                              unlockedForm={totalPatientOrders < 2}
                              patient={order.patient}
                              order={order}
                              setFormDirty={setPatientFormDirty}
                              locked={
                                verification.PATIENT === 'verified' &&
                                order?.testType !== 'HST' &&
                                order?.testType !== 'PSG'
                              }
                            />
                            <div className="col-sm-12 d-flex justify-content-end">
                              <Button
                                className="btn btn-primary"
                                label="Save Changes"
                                type="submit"
                                form="patientUpsert"
                                disabled={!patientFormDirty}
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Accordion.Collapse>
                  </Accordion.Item>
                </StyledAccordion>

                <StyledAccordion
                  defaultActiveKey="0"
                  activeKey={panelStates[Panels.PHYSICIAN] ? '0' : '1'}
                  className="mt-3"
                  onSelect={() =>
                    setPanelStates((p) => p.map((i, idx) => (idx === Panels.PHYSICIAN ? !i : i)))
                  }>
                  <Accordion.Item eventKey="0">
                    <TooltipWrapper orderState={orderState}>
                      <Accordion.Header>
                        <div>Physician</div>
                        {showVerifyButtons && (
                          <VerifyStatusButton
                            disabled={!orderState?.isUnderVerification || !order?.physicianAddress}
                            verifyButtonDisabled={order?.physicianAddress?.status !== 'approved'}
                            value={verification.PHYSICIAN}
                            onChange={handleVerificationChange(VerificationSection.PHYSICIAN)}
                          />
                        )}
                      </Accordion.Header>
                    </TooltipWrapper>
                    <Accordion.Body>
                      <fieldset
                        disabled={
                          verification.PHYSICIAN === 'verified' && order?.testType !== 'PSG'
                        }>
                        <PhysicianSection
                          order={order}
                          allowEdits={
                            (showVerifyButtons && orderState?.isUnderVerification) ||
                            order?.testType === 'PSG'
                          }
                        />
                      </fieldset>
                    </Accordion.Body>
                  </Accordion.Item>
                </StyledAccordion>

                <StyledAccordion
                  defaultActiveKey="0"
                  activeKey={panelStates[Panels.INSURANCE] ? '0' : '1'}
                  className="mt-3"
                  onSelect={() =>
                    setPanelStates((p) => p.map((i, idx) => (idx === Panels.INSURANCE ? !i : i)))
                  }>
                  <Accordion.Item eventKey="0">
                    <TooltipWrapper orderState={orderState}>
                      <Accordion.Header>
                        <div className="col-sm-6 py-2">Insurance</div>
                      </Accordion.Header>
                    </TooltipWrapper>
                    <Accordion.Body className="p-3">
                      <div className="col-sm-12 p-0">
                        {showVerifyButtons &&
                          !insuranceFormDirty &&
                          !disableInsuranceVerification && (
                            <div className="row mb-2">
                              {[
                                true,
                                orderState.isPayer2VerificationRequired,
                                orderState.isPayer3VerificationRequired,
                              ].map(
                                (i, idx) =>
                                  i && (
                                    <div className="col d-flex justify-content-center">
                                      <VerifyStatusButton
                                        disabled={!orderState?.isUnderVerification}
                                        verifyButtonDisabled={!order?.payers?.[idx]?.labPayerId}
                                        value={
                                          idx === 0
                                            ? verification.PAYER
                                            : verification[`PAYER_${idx + 1}`]
                                        }
                                        onChange={
                                          idx === 0
                                            ? handleVerificationChange(VerificationSection.PAYER)
                                            : handleVerificationChange(
                                                VerificationSection[`PAYER_${idx + 1}`],
                                              )
                                        }
                                      />
                                    </div>
                                  ),
                              )}
                            </div>
                          )}
                        {insuranceFormDirty && (
                          <div className="row mb-2 border p-2">
                            <div className="col d-flex justify-content-center">
                              Save insurance changes before verifying
                            </div>
                          </div>
                        )}
                        {disableInsuranceVerification && (
                          <div className="row mb-2 border p-2">
                            <div className="col d-flex justify-content-center">
                              Enter copay amount before verifying
                            </div>
                          </div>
                        )}
                        <LabInsuranceForm
                          order={order}
                          refetchOrder={refetchOrder}
                          updatePayers={updatePayers}
                          insuranceFormDirty={insuranceFormDirty}
                          setInsuranceFormDirty={setInsuranceFormDirty}
                          setOrderPayerInfo={setOrderPayerInfo}
                          verification={verification}
                          isUnderVerification={orderState?.isUnderVerification}
                        />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </StyledAccordion>

                <StyledAccordion
                  defaultActiveKey="0"
                  activeKey={panelStates[Panels.DIAGNOSIS] ? '0' : '1'}
                  className="mt-3"
                  onSelect={() =>
                    setPanelStates((p) => p.map((i, idx) => (idx === Panels.DIAGNOSIS ? !i : i)))
                  }>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="col-sm-6 py-2">Diagnosis</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <DiagnosisCodes
                        order={order}
                        codes={order?.diagnosis}
                        onAdd={addCode}
                        onRemove={removeCode}
                        disabled={!orderState?.isUnderVerification && order?.testType !== 'PSG'}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </StyledAccordion>
              </StyledColumn>
              <StyledColumn className="col-sm-12 col-md-6">
                <div className="row">
                  {order?.brightreeSource && (
                    <StyledAccordion defaultActiveKey="1">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className="col-sm-5">Brightree Data</div>
                        </Accordion.Header>
                        <Accordion.Body
                          css={{
                            padding: 0,
                          }}>
                          <BrightreeLogs order={order} />
                        </Accordion.Body>
                      </Accordion.Item>
                    </StyledAccordion>
                  )}
                  <StyledAccordion
                    defaultActiveKey="0"
                    className={order?.brightreeSource && 'mt-3'}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="col-sm-5">Messages and Notes</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <TabControl
                          orderId={Number(orderId)}
                          tab={bottomTab}
                          setTab={setBottomTab}
                          messages={messages}
                          addMessage={addMessage}
                          updateMessageReadStatus={updateMessageReadStatus}
                          notes={notes}
                          addNote={addNote}
                          deleteNote={deleteNote}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </StyledAccordion>
                  <StyledAccordion defaultActiveKey="0" className="mt-3">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="col-sm-5">Documents</div>
                        <Upload
                          className="invisible"
                          ref={uploadRef}
                          handleUpload={handleUpload}
                          invisible
                        />
                        <div className="col-sm-7 d-flex justify-content-end pr-2 ml-0">
                          {order?.testType !== 'HST' && (
                            <DropdownButton
                              title="Request AOB "
                              onClick={(e) => e.stopPropagation()}>
                              <StyledDropdownItem
                                onClick={aobSignRequest('email')}
                                disabled={!canEmailAob}>
                                Email {!canEmailAob && '(missing)'}
                              </StyledDropdownItem>
                              <StyledDropdownItem
                                onClick={aobSignRequest('sms')}
                                disabled={!canTextAob}>
                                SMS {!canTextAob && '(missing)'}
                              </StyledDropdownItem>
                            </DropdownButton>
                          )}
                          <DropdownButton
                            title="Upload Document "
                            onClick={(e) => e.stopPropagation()}
                            className="mr-2 ml-2"
                            css={{ cursor: 'default' }}>
                            <StyledDropdownItem
                              disabled={rxUploaded}
                              onClick={startUpload('RX')}
                              css={{ cursor: rxUploaded ? 'default' : 'pointer' }}>
                              RX {rxUploaded && <span>(Uploaded)</span>}
                            </StyledDropdownItem>
                            {order?.testType !== 'HST' && (
                              <StyledDropdownItem
                                disabled={aobUploaded}
                                onClick={startUpload('AOB')}>
                                AOB {aobUploaded && <span>(Uploaded)</span>}
                              </StyledDropdownItem>
                            )}
                            {order?.testType === 'HST' && (
                              <StyledDropdownItem
                                disabled={questionnaireUploaded}
                                onClick={startUpload('PAPERWORK')}>
                                Questionnaire {questionnaireUploaded && <span>(Uploaded)</span>}
                              </StyledDropdownItem>
                            )}
                            <StyledDropdownItem onClick={startUpload('OFFICE_NOTES')}>
                              Office Notes
                            </StyledDropdownItem>
                            <StyledDropdownItem onClick={startUpload('BILLING_DOC')}>
                              Billing Doc
                            </StyledDropdownItem>
                            <StyledDropdownItem onClick={startUpload('OTHER')}>
                              Other
                            </StyledDropdownItem>
                          </DropdownButton>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {uploadTypes.map((uploadType: UploadType) => (
                          <DocumentPanel
                            className="mb-3"
                            orderState={orderState}
                            key={uploadType}
                            order={order}
                            uploadType={uploadType}
                            removeFile={removeFile}
                            verification={verification[uploadType]}
                            setVerification={handleVerificationChange(uploadType)}
                            showVerifyButtons={showVerifyButtons}
                            rxUploaded={rxUploaded}
                            aobUploaded={aobUploaded}
                          />
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </StyledAccordion>
                </div>
              </StyledColumn>
            </div>
          ) : (
            <TestSmarterFulfillment order={order} isOrderCompleted={!!orderState?.isReportDone} />
          )
        }
        headerRightContent={
          <div className="col d-flex justify-content-end my-auto pr-2 align-items-center">
            {!!order?.rxRecognitionData?.length && (
              <BsButton
                variant="outline-light"
                href={
                  `mailto:chip@synthpop.ai?subject=TestSmarter Task ID ${order?.rxRecognitionData?.[0]?.synthpopTaskId}` +
                  `&body=${encodeURIComponent(`Please review task #: ${order?.rxRecognitionData?.[0]?.synthpopTaskId}\n\nIssues Found:\n`)}`
                }
                target="_blank"
                className="mr-2">
                <i className="fas fa-flag mr-2"></i> Synthpop Issue
              </BsButton>
            )}
            {order?.thirdPartyDevice?.deviceType === 'NIGHT_OWL' && order?.testType !== 'HST' && (
              <BsButton
                variant="light"
                onClick={() => navigate(`/lab/orderEdit/nightOwl/${order.id}`)}
                className="mr-2">
                <img src={NightOwlIcon} alt="nightOwl" /> Manage NightOwl
              </BsButton>
            )}
            {bucket === 'BILLING_REVIEW' && (
              <BsButton
                variant="light"
                css={{ marginRight: '10px' }}
                onClick={() =>
                  confirm('', {
                    title: 'Submit to CureMD',
                    description: 'Are you sure you want to submit this order for billing?',
                    yesAction: 'Yes',
                  })
                    .then(() =>
                      addToBillingQueue({ orders: [Number(orderId)] })
                        .unwrap()
                        .then(navigateToList),
                    )
                    .catch()
                }>
                Submit to CureMD
              </BsButton>
            )}
            {order?.thirdPartyDevice?.deviceType !== 'TESTSMARTER' &&
              order?.thirdPartyDevice?.deviceType !== 'NIGHT_OWL' &&
              !orderState?.isReportDone &&
              order?.testType !== 'PSG' &&
              order?.testType !== 'HST' &&
              order?.office?.oneShipSupported && (
                <BsButton
                  variant="light"
                  css={{ marginRight: '10px' }}
                  disabled={
                    !(
                      verification.PATIENT === 'verified' &&
                      verification.PHYSICIAN === 'verified' &&
                      verification.RX === 'verified'
                    ) || orderState?.isUnderVerification
                  }
                  onClick={async () => {
                    await initiateTestSmarterFulfillment({
                      orderId: Number(orderId),
                    })
                    setFulfillmentPageOpen(true)
                  }}>
                  Switch to TestSmarter OneShip
                </BsButton>
              )}
            {order?.bucket === 'COMPLETED' &&
              (order.testType === 'PSG' || order.testType === 'HST') && (
                <BsButton
                  variant="light"
                  css={{ marginRight: '10px' }}
                  onClick={() => {
                    navigate(
                      `${routes.index}${routes.order.root}newpatient/${order?.patientId}/orderDetails?officeId=${order?.officeId}&testType=${order.testType}&moveOrderId=${order.id}`,
                    )
                  }}>
                  + Create New {order.testType === 'PSG' ? 'In-Lab' : 'Home'} Sleep Test Order
                </BsButton>
              )}
            {order?.thirdPartyDevice?.deviceType === 'TESTSMARTER' && order?.testType !== 'HST' && (
              <BsButton
                variant={'outline-light mr-2'}
                onClick={() => setFulfillmentPageOpen((x) => !x)}>
                {fulfillmentPageOpen ? 'Change Demographics' : 'Back to Device Shipment'}
              </BsButton>
            )}
            {!order?.cancelled ? (
              !orderState?.isReportDone &&
              !fulfillmentPageOpen && (
                <BsButton variant="outline-light" onClick={() => setShowCancelDialog(true)}>
                  <i className="fas fa-minus-circle mr-2"></i> Cancel Order
                </BsButton>
              )
            ) : !order?.movedTo ? (
              <BsButton
                variant="outline-light"
                onClick={() =>
                  confirm('', {
                    title: 'Restore Order',
                    description: 'Are you sure you want to restore this order?',
                    yesAction: 'Yes',
                  })
                    .then(() => restoreOrder({ orderId: Number(orderId) }))
                    .catch()
                }>
                <i className="fas fa-plus-circle mr-2"></i> Restore Order
              </BsButton>
            ) : (
              <></>
            )}
            {/* <OrderMsgDropdown orderId={Number(orderId)} /> */}
          </div>
        }
      />
    </div>
  )
}

export default OrderEdit
