/** @jsxImportSource @emotion/react */
import { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { FormikProps } from 'formik'

import { PayerSelectionForm } from 'components/lab/orderEdit/payor/PayerSelectionForm'
import { Order, Payer } from 'store/types'
import { OrderVerifyStatus } from './OrderEdit'
import Button from 'components/atoms/Button'
import { PreAuthorizationForm, FormValues } from './payor/PreAuthorizationForm'
import { useSetCopayAmountMutation } from 'store/services/labOrder'

interface IProps {
  order?: Order
  updatePayers: (p: Payer[]) => undefined | Promise<void>
  insuranceFormDirty: boolean
  setInsuranceFormDirty: (v: boolean) => void
  refetchOrder: () => void
  setOrderPayerInfo: (data: any) => void
  verification: OrderVerifyStatus
  isUnderVerification: boolean
}

export const LabInsuranceForm = ({
  order,
  updatePayers,
  insuranceFormDirty,
  setInsuranceFormDirty,
  refetchOrder,
  setOrderPayerInfo,
  verification,
  isUnderVerification,
}: IProps) => {
  const [setCopayAmount] = useSetCopayAmountMutation()

  const [payers, setPayers] = useState<Payer[]>(
    order?.payers?.length
      ? order.payers
      : [{ id: '__new__', name: '', memberId: '', groupId: '', position: 1 }],
  )
  const [errors, setErrors] = useState<Record<string, string>>({})
  const payerSelected = payers?.length
  const formRef = useRef<FormikProps<FormValues>>()

  useEffect(() => {
    if (order?.payers?.length) {
      setPayers(
        order.payers.map((x) => ({
          ...x,
          effectiveDate: x.effectiveDate ? moment(x.effectiveDate).format('MM/DD/YYYY') : undefined,
          terminationDate: x.terminationDate
            ? moment(x.terminationDate).format('MM/DD/YYYY')
            : undefined,
        })),
      )
    }
  }, [order])

  const validate = (): boolean => {
    if (!payerSelected) return false
    let valid = true
    const errors = {}
    payers.forEach((payer) => {
      if (!payer.memberId) {
        valid = false
        errors[payer.position] = { memberId: 'Member ID required' }
      }
      if (!payer.name) {
        valid = false
        errors[payer.position] = {
          ...(errors[payer.position] || {}),
          name: 'Payor selection required',
        }
      }
      if (payer.effectiveDate && payer.effectiveDate.trim().length !== 10) {
        valid = false
        errors[payer.position] = {
          ...(errors[payer.position] || {}),
          effectiveDate: 'Effective Date Invalid',
        }
      }
      if (payer.terminationDate && payer.terminationDate.trim().length !== 10) {
        valid = false
        errors[payer.position] = {
          ...(errors[payer.position] || {}),
          terminationDate: 'Termination Date Invalid',
        }
      }
    })
    setErrors(errors)
    return valid
  }

  const onSubmit = async (values) => {
    if (validate()) {
      await updatePayers(payers)
      await setOrderPayerInfo({
        orderId: order?.id,
        followUpDate: values.followUpDate ? moment(values.followUpDate).format('YYYY-MM-DD') : '',
        notes: values.notes,
        authorizationNumber: values.authorizationNumber,
        authorizationState: values.authorizationState,
        copayAmount: values.copayAmount,
        authorizationValidStartDate: values.authorizationValidStartDate,
        authorizationValidEndDate: values.authorizationValidEndDate,
      }).unwrap()
      await setCopayAmount({
        orderId: Number(order?.id),
        value: values.copayAmount,
      }).unwrap()
      setInsuranceFormDirty(false)
    }
  }

  return (
    <>
      <PayerSelectionForm
        orderId={Number(order?.id)!}
        payers={payers}
        setPayers={setPayers}
        officeId={order?.officeId!}
        setFormDirty={setInsuranceFormDirty}
        insuranceFormDirty={insuranceFormDirty}
        refetchOrder={refetchOrder}
        errors={errors}
        verification={verification}
        isUnderVerification={isUnderVerification}
        testType={order?.testType}
      />
      <div className="col-12 mt-2">
        <hr className="mt-0" />
      </div>

      <PreAuthorizationForm
        onSubmit={onSubmit}
        order={order}
        setDirty={setInsuranceFormDirty}
        formikRef={formRef}
      />

      <div className="col-sm-12 p-0 pt-2 d-flex justify-content-end">
        {insuranceFormDirty && (
          <Button
            className="btn btn-outline-danger mr-2"
            label="Undo"
            onClick={() => {
              formRef.current?.resetForm()
              if (order) setPayers(order.payers)
            }}
          />
        )}
        <Button
          className="btn btn-primary"
          label="Save Changes"
          type="submit"
          form="insuranceInfo"
          disabled={!insuranceFormDirty || !payerSelected}
        />
      </div>
    </>
  )
}
