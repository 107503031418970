/** @jsxImportSource @emotion/react */
import Moment from 'react-moment'
import moment from 'moment'
import _ from 'lodash'
import { css } from '@emotion/react'
import Button from 'react-bootstrap/Button'

import * as generalHelper from 'helpers/generalHelper'
import routes from '../constants/routes'
import { PAYER_STATUS_OPTIONS } from '../constants/orderStatus'
import Link from 'components/atoms/Link'
import { Pill } from 'components/atoms/Pill'
import Checkbox from 'components/atoms/IndeterminateCheckbox'
import { bucketColumnDefaults } from './columnConfig'
import { PopoverWrapper } from 'components/atoms/PopoverWrapper'
import { OrderProgressBar } from 'components/molecules/OrderProgressBar'
import NightOwlIcon from 'assets/img/nightowl-icon.svg'

const bucketToFollowUpNamespace = {
  [routes.order.hst.patientConsent.bucket]: 'HST_PATIENT_CONSENT',
  [routes.order.hst.patientScheduling.bucket]: 'HST_PAPERWORK',
}

const dayClass = (row) => {
  const diff = moment().diff(moment(row.createdAt), 'day')
  if (diff > 21) {
    return 'btn-danger'
  }
  if (diff > 15) {
    return 'btn-orange'
  }
  if (diff > 7) {
    return 'btn-warning'
  }
  return 'btn-secondary'
}

const getDeviceCategory = (row: any) => {
  if (row && row?.device_category) {
    return <span className="font-weight-bold m-2">{row?.device_category?.name}</span>
  }
}

const getPatient = (row: any, type: string, bucket: string) => {
  if (row) {
    if (type === 'name') {
      const label = row.patient
        ? `${row.patient.firstName} ${row.patient.lastName}`
        : `No patient #${row.id}`
      return (
        <Link
          to={`${routes.index}${routes.order.edit}${row.id}?bucket=${bucket}`}
          label={label}
          style={{ fontSize: '1.1rem' }}
        />
      )
    } else if (type === 'dob' && row.patient && row.patient.dob) {
      return (
        <small>
          <Moment format="MM/DD/YYYY" date={row.patient.dob} />
        </small>
      )
    } else if (type === 'order_id') {
      return (
        <>
          <span className="p-1 border border-primary rounded mt-1 mr-1">
            <Link to={`${routes.index}${routes.order.edit}${row.id}`} label={`#${row.id}`} />
          </span>
          {getDeviceCategory(row)}
        </>
      )
    }
  }
  return ''
}

/**
 * List of table columns
 */
const _columnsFunction = ({
  testType,
  bucket,
  setSelectedIds,
  selectedIds,
  setShowAuthorizationDialog,
  stats,
}: {
  testType: 'POX' | 'COX' | 'HST' | 'PSG'
  bucket: string
  setSelectedIds: (fn: (ids: number[]) => number[]) => void
  selectedIds: number[]
  setShowAuthorizationDialog: (orderId: number) => void
  stats?: { [key: string]: { [key: string]: string } }
}) => {
  const allColumns = [
    {
      id: 'select',
      accessor: 'select',
      disableSortBy: true,
      Header: ({ rows }) => {
        return (
          <Checkbox
            css={{ marginLeft: '5px' }}
            indeterminate={!!(selectedIds?.length && selectedIds?.length < rows.length)}
            checked={selectedIds?.length === rows.length}
            onChange={() =>
              setSelectedIds(
                selectedIds?.length === rows.length
                  ? []
                  : rows.map(({ original }) => Number(original.id)),
              )
            }
          />
        )
      },
      Cell: ({ row }) => (
        <div className="pl-1 pr-2">
          <Checkbox
            checked={selectedIds.includes(Number(row.original.id))}
            onChange={(e) =>
              setSelectedIds((prev) => {
                const orderId = Number(row.original.id)
                if (prev.includes(orderId)) {
                  return prev?.filter((id) => id !== orderId)
                }
                return [...prev, orderId]
              })
            }
          />
        </div>
      ),
    },
    {
      Header: bucket === 'INS_VER' ? 'Order ID' : 'Order Information',
      accessor: 'id', // accessor is the "key" in the data,
      className: 'col-3',
      sortAccessor: ['firstName', 'lastName'],
      filterAccessor: ['id', 'firstName', 'lastName'],
      Cell: (props) => {
        const row = props.row.original
        if (bucket === 'INS_VER') {
          return (
            <div
              css={{
                '& .order-info:hover': {
                  '& .open-new-tab': {
                    display: 'block',
                  },
                },
              }}>
              <div className="d-flex align-items-center order-info position-relative">
                <Link
                  className="border border-primary rounded p-2 mr-4 d-flex align-items-center"
                  to={`${routes.index}${routes.order.edit}${row.id}`}
                  label={row.id}
                />
                <Link
                  className="open-new-tab"
                  css={{ display: 'none', position: 'absolute', right: '0' }}
                  to={`${routes.index}${routes.order.edit}${row.id}`}
                  target="_blank"
                  icon="fas fa-external-link-alt"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            </div>
          )
        }
        return (
          <div
            css={{
              '& .order-info:hover': {
                '& .open-new-tab': {
                  display: 'block',
                },
              },
            }}>
            <div className="d-flex align-items-center order-info position-relative">
              <Link
                className="border border-primary rounded p-2 mr-1 d-flex align-items-center"
                to={`${routes.index}${routes.order.edit}${row.id}`}
                label={`#${row.id}`}
              />
              <div className="d-flex flex-column ml-1">
                <div className="text-bold" style={{ whiteSpace: 'nowrap' }}>
                  {getPatient(row, 'name', bucket)}
                  {row.isNightOwl && <img src={NightOwlIcon} alt="NO" />}
                  {row.isOneShip && <span className="text-gray">OneShip</span>}
                  {row.isBrightree && <span>BT</span>}
                </div>
                <div css={{ fontSize: '0.9rem' }}>DOB {getPatient(row, 'dob', bucket)}</div>
              </div>
              {bucket !== 'COMPLETED' && (
                <div
                  className={`text-bold d-flex justify-content-center align-items-center mt-1 ml-4 btn btn-sm w-30 ${dayClass(
                    row,
                  )}`}
                  style={{
                    maxHeight: '22px',
                    minWidth: '48px',
                    pointerEvents: 'none',
                    whiteSpace: 'nowrap',
                  }}>
                  <small>
                    {moment().diff(moment(row.createdAt), 'day')} {generalHelper.t('Days')}
                  </small>
                </div>
              )}
              {row.cancelled && (
                <div
                  className={`text-bold d-flex justify-content-center align-items-center mt-1 ml-2 btn btn-sm w-30 btn-danger`}
                  style={{
                    maxHeight: '22px',
                    minWidth: '48px',
                    pointerEvents: 'none',
                    whiteSpace: 'nowrap',
                  }}>
                  <small>Cancelled</small>
                </div>
              )}
              <Link
                className="open-new-tab"
                css={{ display: 'none', position: 'absolute', right: '0' }}
                to={`${routes.index}${routes.order.edit}${row.id}`}
                target="_blank"
                icon="fas fa-external-link-alt"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>
        )
      },
    },
    {
      Header: 'Procedure',
      accessor: 'procedure',
      className: 'col-2',
      Cell: (props) => {
        const procedure = props.row.original?.tests[0]?.procedure

        return procedure || <></>
      },
    },
    {
      Header: 'Step',
      accessor: 'inLabStep',
      className: 'col-2',
      Cell: (props) => {
        const procedure = props.row.original?.tests[0]?.procedure

        return procedure || <></>
      },
    },
    {
      Header: 'Fax Uploaded Date',
      accessor: 'faxUploadedDate',
      className: 'col-2',
      Cell: (props) => {
        const failedFaxes = props.row.original.faxing?.filter(
          (f) => f.status === 'FAILED_PERMANENTLY',
        )
        if (failedFaxes?.length) {
          return failedFaxes.map((f) => (
            <div key={f.id}>
              <small>
                <Moment format="MM/DD/YYYY" date={f.uploadedAt} />
              </small>
            </div>
          ))
        }
        return <></>
      },
    },
    {
      Header: 'Physician Name',
      accessor: 'physician',
      className: 'col-3',
      Cell: (props) =>
        props.row.original.physician ? (
          <div className="d-flex">
            {props.row.original.physician?.lastName}, {props.row.original.physician?.firstName}
          </div>
        ) : null,
    },
    {
      Header: 'Physican Fax #',
      accessor: 'faxNumber',
      className: 'col-3',
      Cell: (props) => <div className="d-flex">{props.row.original.physician?.fax}</div>,
    },
    {
      Header: 'Fax Attempts',
      accessor: 'faxAttempts',
      className: 'col-3',
      Cell: (props) => {
        const failedFaxAttempts = props.row.original.faxing
          ?.filter((f) => f.status === 'FAILED_PERMANENTLY' || f.status === 'SENT')
          ?.reduce((acc, curr) => {
            acc = acc + curr.faxAttempts
            return acc
          }, 0)

        return <small>{failedFaxAttempts}</small>
      },
    },
    {
      Header: 'Last Fax Attempt Date',
      accessor: 'lastFaxAttemptDate',
      className: 'col-3',
      Cell: (props) => {
        const failedFaxes = props.row.original.faxing
          ?.filter((f) => f.status === 'FAILED_PERMANENTLY')
          .sort((a, b) => b.when - a.when)

        if (failedFaxes?.length) {
          return (
            <div>
              <small>
                <Moment format="MM/DD/YYYY" date={failedFaxes[0].when} />
              </small>
            </div>
          )
        }
        return <></>
      },
    },
    {
      Header: 'Successful Attempts',
      accessor: 'successfulFaxes',
      className: 'col-3',
      Cell: ({ row }) => {
        const { faxing } = row.original
        const lastFax = faxing?.filter((f) => f.faxNumber).pop()

        const faxStats = (stats || {})[lastFax?.faxNumber]
        const successCount = faxStats?.SENT
        return successCount ? <small>{successCount}</small> : <>-</>
      },
    },
    {
      Header: 'In Transit',
      accessor: 'shippedAt',
      className: 'col-1',
      sortAccessor: 'shippedAt',
      Cell: ({ row }) => {
        const shippedAt = moment(row.original.shippedAt)
        const diff = moment().diff(shippedAt, 'day')
        let className = 'btn-secondary'
        if (diff > 21) {
          className = 'btn-danger'
        } else if (diff > 15) {
          className = 'btn-orange'
        } else if (diff > 7) {
          className = 'btn-warning'
        }
        return <span className={`${className} px-2 py-1 rounded`}>{diff} Days</span>
      },
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      className: 'col-1',
      sortAccessor: 'firstName',
      filterAccessor: 'firstName',
      Cell: ({ row }) => <span>{row.original.patient.firstName}</span>,
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      className: 'col-1',
      sortAccessor: 'lastName',
      filterAccessor: 'lastName',
      Cell: ({ row }) => <span>{row.original.patient.lastName}</span>,
    },
    {
      Header: 'DOB',
      accessor: 'dob',
      className: 'col-1',
      sortAccessor: 'dob',
      filterAccessor: 'dob',
      Cell: ({ row }) => <span>{moment(row.original.patient.dob).format('MM/DD/YYYY')}</span>,
    },
    {
      Header: 'Order Age',
      accessor: 'orderAge',
      className: 'col-1',
      sortAccessor: 'orderAge',
      filterAccessor: 'orderAge',
      Cell: ({ row }) => (
        <small style={{ whiteSpace: 'nowrap', fontSize: '0.9rem' }}>
          {moment().diff(moment(row.original.createdAt), 'day')} {generalHelper.t('Days')}
        </small>
      ),
    },
    {
      Header: 'OneShip',
      accessor: 'oneship',
      className: 'col-1',
      sortAccessor: 'oneship',
      filterAccessor: 'oneship',
      Cell: ({ row }) => (
        <small style={{ whiteSpace: 'nowrap' }}>{row.original.isOneShip ? 'Y' : '-'}</small>
      ),
    },
    {
      id: 'status',
      Header: 'Status',
      className: 'col-1',
      accessor: 'bucket',
      canFilter: false,
      style: { verticalAlign: 'middle' },
      Cell: ({ row }) => (
        <span>{_.startCase(row.original.bucket.replace(/_/g, ' ').toLowerCase())}</span>
      ),
    },
    {
      id: 'hstBucket',
      Header: 'Sleep Testing Bucket',
      className: 'col-2',
      accessor: 'hstBucket',
      canFilter: false,
      style: { verticalAlign: 'middle' },
      Cell: ({ row }) => (
        <span>
          {_.startCase(
            row.original.labBuckets
              ?.filter(
                (i) =>
                  i.startsWith('HST_') || i.startsWith('PSG_') || i === 'OPEN' || i === 'COMPLETED',
              )
              .join(', ')
              .replace(/HST_/g, '')
              .replace(/PSG_/g, '')
              .replace(/_/g, ' ')
              .toLowerCase(),
          )}
        </span>
      ),
    },
    {
      id: 'officeName',
      Header: 'DME Client',
      accessor: 'officeName',
      className: 'col-1',
      canFilter: false,
      Cell: ({ row }) => <span css={{ fontSize: '0.9rem' }}>{row.original?.officeName}</span>,
    },
    {
      Header: 'Progress',
      accessor: 'progress',
      className: 'col-4',
      disableSortBy: true,
      Cell: (props) => {
        const order = props.row.original
        return <OrderProgressBar context="LAB" orderData={order} testType={testType} />
      },
    },
    {
      Header: 'Device Checked Out',
      className: 'col-1',
      accessor: 'deviceAssignedAt',
      sortInverted: true,
      canFilter: false,
      Cell: ({ row }) => {
        const { deviceAssignedAt } = row.original
        const days = deviceAssignedAt ? moment().diff(moment(deviceAssignedAt), 'day') : null
        if (days === null) {
          return <span>-</span>
        }
        return (
          <div
            className={`text-bold d-flex justify-content-center align-items-center btn btn-sm w-30 ${dayClass(
              row,
            )}`}
            style={{
              maxHeight: '22px',
              minWidth: '48px',
              maxWidth: '75px',
              pointerEvents: 'none',
              whiteSpace: 'nowrap',
            }}>
            {days === 0 ? (
              <span>Today</span>
            ) : (
              <span>
                {days} {days > 1 ? 'days' : 'day'}
              </span>
            )}
          </div>
        )
      },
    },
    {
      Header: 'Notes',
      className: 'col-1',
      accessor: 'notes',
      disableSortBy: true,
      Cell: ({ row }) => {
        const notes = row?.original?.notes
        return notes?.count ? (
          <PopoverWrapper
            placement="left"
            customHeader="Recent Notes"
            customContent={
              <div className="p-2">
                {notes.last?.map((n) => (
                  <p
                    className="p-2 mb-3"
                    css={css`
                      background-color: lightyellow;
                      border: 1px solid #ffc10780;
                    `}>
                    {moment(n.createdAt).format('MM/DD/YYYY HH:mm a')}:<br /> {n.text}
                  </p>
                ))}
              </div>
            }>
            <div className="w-100">
              <Pill
                css={css`
                  background-color: lightyellow;
                  border: 1px solid #ffc10780;
                `}
                to={`${routes.index}${routes.order.edit}${row.original.id}`}>
                {notes.count ?? 0} Note{notes.count > 1 ? 's' : ''}
              </Pill>
            </div>
          </PopoverWrapper>
        ) : (
          '-'
        )
      },
    },
    {
      Header: 'Conditions',
      className: 'col-2',
      accessor: 'tests',
      Cell: ({ row }) => (
        <ul className="tcWidget_list p-0">
          {Array.isArray(row.original.tests) ? (
            row.original.tests.map((t, i) => (
              <li key={i}>
                <span>{`Night ${i + 1}`}: </span>
                <span className={`setting ${!t.oxygen ? 'setting-air' : ''}`}>
                  {t.oxygen ? 'OXYGEN' : 'ROOM AIR'}{' '}
                  {t.oxygen ? <span className="value">{t.oxygenLpm}</span> : ''}
                </span>
                {t.papType ? (
                  <span
                    className={`type ${
                      t.papType === 'BiPAP' ? 'type-bipap' : t.papType === 'CPAP' ? 'type-cpap' : ''
                    }`}>
                    {' '}
                    - {t.papType}{' '}
                    <span className="value">
                      {t.papType === 'APAP'
                        ? `${t.apapMin} - ${t.apapMax}`
                        : t.papType === ' - BiPAP '
                          ? `${t.bipapI} - ${t.bipapE}`
                          : ` - ${t.cpap} `}
                    </span>
                  </span>
                ) : null}
              </li>
            ))
          ) : (
            <p className="noData">No Nights Currently Added.</p>
          )}
        </ul>
      ),
    },
    {
      Header: 'Physician',
      className: 'col-4',
      accessor: 'physician',
      canFilter: false,
      Cell: ({ row }) => {
        return row.original.physician ? (
          <div style={{ minWidth: '12vw' }}>
            {row.original?.physician?.lastName}, {row.original?.physician?.firstName}
          </div>
        ) : (
          <>-</>
        )
      },
    },
    {
      Header: 'Current Issues',
      className: 'col-2',
      accessor: 'tasks',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { tasks } = row.original
        if (tasks?.length === 0) return '-'

        return (
          <PopoverWrapper
            placement="left"
            customHeader="Current Issues"
            customContent={
              <div className="p-2">
                {tasks
                  ?.filter((t) => t.blocking && !t.resolved)
                  ?.map((t) => <p>{_.startCase(t.namespace.replace(/_/g, ' ').toLowerCase())}</p>)}
              </div>
            }>
            <span>
              <Link
                to={`${routes.index}${routes.order.edit}`}
                label={<>{tasks?.length ?? 0} issue(s)</>}
              />
            </span>
          </PopoverWrapper>
        )
      },
    },
    {
      Header: 'Direct Messages',
      className: 'col-2',
      accessor: 'messages',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { messages } = row.original
        const unread = messages?.count - messages?.read
        if (!messages?.count) return '-'
        return (
          <PopoverWrapper
            placement="left"
            customHeader="Recent Messages"
            customContent={
              messages.last?.length > 0 && (
                <div className="p-2">
                  {messages.last?.map((m) => (
                    <p>
                      {moment(m.createdAt).format('MM/DD/YYYY HH:mm a')}:<br /> {m.text}
                    </p>
                  ))}
                </div>
              )
            }>
            <span>
              <Link
                to={`${routes.index}${routes.order.edit}${row.id}`}
                label={
                  <>
                    {unread ?? 0} unread
                    <br />
                    {messages?.count} total
                  </>
                }
              />
            </span>
          </PopoverWrapper>
        )
      },
    },
    {
      Header: 'Primary Payor Status',
      className: 'col-1',
      accessor: 'primaryPayerStatus',
      Cell: ({ row }) => {
        const primaryPayer = row.original.payers?.find((i) => i.position === 1)

        return primaryPayer ? (
          <span>
            {PAYER_STATUS_OPTIONS.find((i) => i.key === primaryPayer.status)?.value ??
              'Not Started'}
          </span>
        ) : (
          <></>
        )
      },
    },
    {
      Header: 'Primary Payor',
      className: 'col-2',
      accessor: 'primaryPayer',
      Cell: ({ row }) => (
        <PopoverWrapper
          placement="left"
          customHeader="Payor Notes"
          customContent={
            <div className="px-2">
              <p className="px-2 mb-1">
                Member ID: {row.original.payers?.find((p) => p.position === 1)?.memberId}
                <br />
                Authorization #: {row.original.authorizationNumber}
                <br />
                Diagnosis Codes: {row.original.diagnosisCodes?.join(', ')}
              </p>
            </div>
          }>
          <span style={{ fontSize: '0.9rem' }}>
            {row.original.payers?.find((p) => p.position === 1)?.name ??
              row.original.payers?.find((p) => p.position === 1)?.names?.[0] ??
              '-'}
          </span>
        </PopoverWrapper>
      ),
    },
    {
      Header: 'Primary Member ID',
      className: 'col-1',
      accessor: 'primaryPayerId',
      Cell: ({ row }) => (
        <span>{row.original.payers?.find((p) => p.position === 1)?.memberId}</span>
      ),
    },
    {
      Header: 'Secondary Payor Status',
      className: 'col-1',
      accessor: 'secondaryPayerStatus',
      disableSortBy: true,
      Cell: ({ row }) => {
        const secondaryPayer = row.original.payers?.find((i) => i.position === 2)

        return secondaryPayer ? (
          <span style={{ whiteSpace: 'nowrap' }}>
            {PAYER_STATUS_OPTIONS.find((i) => i.key === secondaryPayer.status)?.value ??
              'Not Started'}
          </span>
        ) : (
          <></>
        )
      },
    },
    {
      Header: 'Secondary Payor',
      className: 'col-2',
      accessor: 'secondaryPayer',
      disableSortBy: true,
      Cell: ({ row }) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {row.original.payers?.find((p) => p.position === 2)?.names?.[0] ?? '-'}
        </span>
      ),
    },
    {
      Header: 'Secondary Member ID',
      className: 'col-1',
      accessor: 'secondaryPayerId',
      Cell: ({ row }) => (
        <span>{row.original.payers?.find((p) => p.position === 2)?.memberId}</span>
      ),
    },
    {
      Header: 'Follow-up Date',
      className: 'col-1',
      accessor: 'followUpDateNo',
      Cell: ({ row }) => {
        const owlFollowUpDate = row.original.followUpsList.find(
          (f) => f.namespace === 'NIGHT_OWL',
        )?.date
        return <span>{owlFollowUpDate ? moment(owlFollowUpDate).format('MM/DD/YYYY') : ''}</span>
      },
    },
    {
      Header: 'Follow-up Date',
      className: 'col-1',
      accessor: 'followUpDate',
      Cell: ({ row }) => (
        <span>
          {row.original.payerFollowUpDate
            ? moment(row.original.payerFollowUpDate).format('MM/DD/YYYY')
            : ''}
        </span>
      ),
    },
    {
      Header: 'Follow-up Date',
      className: 'col-1',
      accessor: 'followUpDate:HST',
      Cell: ({ row }) => {
        const followUps = [...(row.original.followUpsList ?? [])].sort((a, b) =>
          a.date > b.date ? -1 : 1,
        )

        const followUp =
          followUps?.find((f) => f.namespace === bucketToFollowUpNamespace[bucket]) ??
          followUps?.[0]
        const date = followUp?.date

        return (
          <PopoverWrapper
            placement="left"
            customHeader="Follow-ups"
            customContent={
              <div className="p-2">
                <p
                  className="p-2 mb-3"
                  css={css`
                    background-color: lightyellow;
                    border: 1px solid #ffc10780;
                  `}>
                  {followUps.map((f) => (
                    <div>
                      <small>
                        {f.namespace}: {moment(f.date).format('MM/DD/YYYY')}
                      </small>
                    </div>
                  ))}
                </p>
              </div>
            }>
            {date ? <span>{date ? moment(date).format('MM/DD/YYYY') : ''}</span> : <></>}
          </PopoverWrapper>
        )
      },
    },
    {
      Header: 'Auth Required',
      className: 'col-1',
      accessor: 'authorizationRequired',
      Cell: ({ row }) => <span>{row.original.authorizationRequired ? 'Y' : '-'}</span>,
    },
    {
      Header: 'Tertiary Payor',
      className: 'col-1',
      accessor: 'tertiaryPayer',
      Cell: ({ row }) => <span>{row.original.hasPayer3 ? 'Y' : '-'}</span>,
    },
    {
      Header: 'Test Type',
      className: 'col-1',
      accessor: 'testType',
      Cell: ({ row }) => <span>{row.original.test_type || row.original.testType}</span>,
    },
    {
      Header: 'Order Completion',
      className: 'col-1',
      accessor: 'orderCompletion',
      Cell: ({ row }) => (
        <span>
          {row.original.sessionsSelectedAt
            ? moment(row.original.sessionsSelectedAt).format('MM/DD/YYYY')
            : '-'}
        </span>
      ),
    },
    {
      Header: 'Days of Service',
      className: 'col-1',
      accessor: 'daysOfService',
      Cell: ({ row }) => <span>{row.original.tests?.length}</span>,
    },
    {
      Header: 'Faxed Status',
      className: 'col-1',
      accessor: 'faxedStatus',
      Cell: ({ row }) => {
        const { faxing } = row.original
        const allSent = !!faxing?.length && faxing?.every((i) => i.status === 'SENT')
        const allFailed = !!faxing?.length && faxing?.every((i) => i.status === 'FAILED')

        return (
          <span>
            {allSent && 'Sent'}
            {allFailed && 'Failed'}
            {!allSent && !allFailed && 'Pending'}
          </span>
        )
      },
    },
    {
      Header: 'Payor Notes',
      className: 'col-1',
      accessor: 'payerNotes',
      Cell: ({ row }) => (
        <PopoverWrapper
          placement="left"
          customHeader="Payor Notes"
          customContent={
            <div className="p-2">
              <p
                className="p-2 mb-3"
                css={css`
                  background-color: lightyellow;
                  border: 1px solid #ffc10780;
                `}>
                {row.original.payersNote}
              </p>
            </div>
          }>
          {row.original.payersNote ? (
            <div className="w-100">
              <Pill
                to={`${routes.index}${routes.order.edit}${row.original.id}`}
                css={css`
                  background-color: lightyellow;
                  border: 1px solid #ffc10780;
                `}>
                {row.original.payersNote?.length > 20
                  ? row.original.payersNote.slice(0, 20).concat('...')
                  : row.original.payersNote}
              </Pill>
            </div>
          ) : (
            <></>
          )}
        </PopoverWrapper>
      ),
    },
    {
      Header: 'Auth State',
      className: 'col-1',
      accessor: 'authorizationState',
      Cell: ({ row }) => (
        <span css={{ whiteSpace: 'nowrap' }}>
          {row.original.authorizationState === 'PENDING' && 'Not Started'}
          {row.original.authorizationState === 'STARTED' && 'Started'}
        </span>
      ),
    },
    {
      Header: 'Auth Exp',
      className: 'col-1',
      accessor: 'authorizationValidTo',
      Cell: ({ row }) => (
        <span>
          {row.original.authorizationValidTo &&
            moment(row.original.authorizationValidTo).format('MM/DD/YYYY')}
        </span>
      ),
    },
    {
      Header: 'Action',
      className: 'col-1',
      accessor: 'updateAuthorization',
      Cell: ({ row }) => (
        <span onClick={(e) => e.stopPropagation()}>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={(e) => {
              setShowAuthorizationDialog(row.original.id)
            }}>
            Update
          </Button>
        </span>
      ),
    },
  ]

  const currentViewColumns =
    bucketColumnDefaults[testType === 'HST' ? 'HST' : testType === 'PSG' ? 'PSG' : 'POXCOX'][bucket]
      ?.map((col) => allColumns.find((c) => c.accessor === col))
      ?.filter((i) => i) || []

  return currentViewColumns
}

export default _columnsFunction
