/** @jsxImportSource @emotion/react */
import Moment from 'react-moment'
import moment from 'moment'
import _ from 'lodash'
import { css } from '@emotion/react'

import * as generalHelper from 'helpers/generalHelper'
import routes from '../constants/routes'
import Link from '../../atoms/Link'
import { PopoverWrapper } from 'components/atoms/PopoverWrapper'
import { Pill } from 'components/atoms/Pill'
import { OrderProgressBar } from 'components/molecules/OrderProgressBar'
import NightOwlIcon from 'assets/img/nightowl-icon.svg'

const dayClass = (row) => {
  const diff = moment().diff(moment(row.createdAt), 'day')
  if (diff > 21) {
    return 'btn-danger'
  }
  if (diff > 15) {
    return 'btn-orange'
  }
  if (diff > 7) {
    return 'btn-warning'
  }
  return 'btn-secondary'
}

const getPatient = (row, type: 'name' | 'dob') => {
  if (row) {
    if (type === 'name') {
      const label = row.patient
        ? `${row.patient.firstName} ${row.patient.lastName}`
        : `No patient #${row.id}`
      return (
        <Link
          to={`${routes.index}${routes.order.edit}${row.id}`}
          label={label}
          style={{ fontSize: '1.3rem' }}
        />
      )
    } else if (type === 'dob' && row.patient && row.patient.dob) {
      return (
        <small>
          <Moment format="MM/DD/YYYY" date={row.patient.dob} />
        </small>
      )
    }
  }
  return ''
}

/**
 * List of table columns
 */
const _columnsFunction = (hiddenCols: string[], testType: string) =>
  [
    {
      Header: 'Order Information',
      accessor: 'id', // accessor is the "key" in the data,
      className: 'col-3',
      sortAccessor: ['firstName', 'lastName'],
      filterAccessor: ['id', 'firstName', 'lastName'],
      Cell: (props) => {
        const row = props.row.original
        return (
          <div className="d-flex align-items-center">
            <Link
              className="border border-primary rounded p-2 mr-1 d-flex align-items-center"
              to={`${routes.index}${routes.order.edit}${row.id}`}
              label={`#${row.id}`}
            />
            <div className="d-flex flex-column ml-1">
              <div className="text-bold mb-1" style={{ whiteSpace: 'nowrap' }}>
                {getPatient(row, 'name')} {row.isNightOwl && <img src={NightOwlIcon} />}
                {row.isBrightree && <span className="text-gray ml-2">Brightree</span>}
              </div>
              <div>DOB {getPatient(row, 'dob')}</div>
            </div>
          </div>
        )
      },
    },
    {
      id: 'status',
      Header: 'Status',
      className: 'col-1',
      accessor: 'bucket',
      canFilter: false,
      style: { verticalAlign: 'middle' },
      Cell: ({ row }) => (
        <span>
          {row.original?.cancelled
            ? 'Cancelled'
            : _.startCase(row.original.bucket.replace(/_/g, ' ').toLowerCase())}
        </span>
      ),
    },
    {
      id: 'procedure',
      Header: 'Procedure',
      accessor: 'procedure',
      className: 'col-1',
      Cell: (props) => {
        const procedure = props.row.original?.tests[0]?.procedure

        return procedure || <></>
      },
    },
    {
      Header: 'Progress',
      accessor: 'progress',
      className: 'col-4',
      disableSortBy: true,
      Cell: (props) => {
        const order = props.row.original
        return <OrderProgressBar context="DME" orderData={order} testType={testType} />
      },
    },
    {
      Header: 'Lab Progress',
      className: 'col-1',
      accessor: 'labProgress',
      Cell: ({ row }) => (
        <span>
          {_.startCase(
            row.original.subBucket?.replace('HST_', '')?.replace('PSG_', '')?.replace(/_/g, ' ')?.toLowerCase(),
          )}
        </span>
      ),
    },
    {
      Header: 'Notes',
      className: 'col-1',
      accessor: 'notes',
      disableSortBy: true,
      Cell: ({ row }) => {
        const notes = row?.original?.notes
        return notes?.count ? (
          <PopoverWrapper
            placement="left"
            customHeader="Recent Notes"
            customContent={
              <div className="p-2">
                {notes.last?.map((n) => (
                  <p
                    className="p-2 mb-3"
                    css={css`
                      background-color: lightyellow;
                      border: 1px solid #ffc10780;
                    `}>
                    {moment(n.createdAt).format('MM/DD/YYYY HH:mm a')}:<br /> {n.text}
                  </p>
                ))}
              </div>
            }>
            <div className="w-100">
              <Pill
                css={css`
                  background-color: lightyellow;
                  border: 1px solid #ffc10780;
                `}
                to={`${routes.index}${routes.order.edit}${row.original.id}`}>
                {notes.count ?? 0} Note{notes.count > 1 ? 's' : ''}
              </Pill>
            </div>
          </PopoverWrapper>
        ) : (
          <div className="d-flex align-items-center justify-content-center">-</div>
        )
      },
    },
    {
      Header: 'Date Ordered',
      accessor: 'dateOrdered',
      Cell: ({ row }) => {
        return (
          <Pill className={`${dayClass(row)}`}>
            {moment().diff(moment(row.original.createdAt), 'day')} {generalHelper.t('Days')}
          </Pill>
        )
      },
    },
    {
      Header: 'Device Checked Out',
      className: 'col-1',
      accessor: 'deviceAssignedAt',
      sortInverted: true,
      canFilter: false,
      Cell: ({ row }) => {
        const { deviceAssignedAt, isOneShip } = row.original
        const days = deviceAssignedAt ? moment().diff(moment(deviceAssignedAt), 'day') : null
        if (days === null) {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div>-</div>
              {isOneShip && (
                <div className="text-gray" css={{ fontWeight: '600' }}>
                  OneShip
                </div>
              )}
            </div>
          )
        }
        return (
          <div className="d-flex align-items-center justify-content-center flex-column">
            <Pill className={`${dayClass(row)}`}>
              {days === 0 ? (
                <span>Today</span>
              ) : (
                <span>
                  {days} {days > 1 ? 'Days' : 'Day'}
                </span>
              )}
            </Pill>
            {isOneShip && (
              <span className="text-gray" css={{ fontWeight: '600' }}>
                OneShip
              </span>
            )}
          </div>
        )
      },
    },
    {
      Header: 'Conditions',
      className: 'col-2',
      accessor: 'tests',
      Cell: ({ row }) => (
        <ul className="tcWidget_list p-0">
          {Array.isArray(row.original.tests) ? (
            row.original.tests.map((t, i) => (
              <li key={i}>
                <span className={`setting ${!t.oxygen ? 'setting-air' : ''}`}>
                  {t.oxygen ? 'OXYGEN' : 'ROOM AIR'}{' '}
                  {t.oxygen ? <span className="value">{t.oxygenLpm}</span> : ''}
                </span>
                {t.papType ? (
                  <span
                    className={`type ${
                      t.papType === 'BiPAP' ? 'type-bipap' : t.papType === 'CPAP' ? 'type-cpap' : ''
                    }`}>
                    {' '}
                    - {t.papType}{' '}
                    <span className="value">
                      {t.papType === 'APAP'
                        ? `${t.apapMin} - ${t.apapMax}`
                        : t.papType === ' - BiPAP '
                          ? `${t.bipapI} - ${t.bipapE}`
                          : ` - ${t.cpap} `}
                    </span>
                  </span>
                ) : null}
              </li>
            ))
          ) : (
            <p className="noData">No Nights Currently Added.</p>
          )}
        </ul>
      ),
    },
    {
      Header: 'Physician',
      className: 'col-4',
      accessor: 'physician',
      canFilter: false,
      Cell: ({ row }) => {
        return row.original.physician ? (
          <div style={{ minWidth: '12vw' }}>
            {row.original?.physician?.lastName}, {row.original?.physician?.firstName}
          </div>
        ) : (
          <>-</>
        )
      },
    },
    {
      Header: 'Direct Messages',
      className: 'col-2',
      accessor: 'messages',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { messages } = row.original
        const unread = messages?.count - messages?.read
        if (!messages?.count)
          return <div className="d-flex align-items-center justify-content-center">-</div>
        if (unread) {
          return (
            <>
              <Pill
                css={css`
                  background-color: #e3342f30;
                  border: 1px solid #e3342f80;
                  margin-bottom: 2px;
                `}
                to={`${routes.index}${routes.order.edit}${row.original.id}`}>
                {unread} New Message{unread > 1 ? 's' : ''}
                <br />
              </Pill>
              {messages?.count} total
            </>
          )
        }
        return (
          <span>
            <span className="text-gray">{unread ?? 0} unread</span>
            <br />
            {messages?.count} total
          </span>
        )
      },
    },
  ].filter((c) => !hiddenCols.includes(c.id) && !hiddenCols.includes(c.accessor))

export default _columnsFunction
