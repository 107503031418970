/** @jsxImportSource @emotion/react */
import { Formik } from 'formik'
import moment from 'moment/moment'
import * as Yup from 'yup'
import PageIcon from 'assets/img/page-icon.svg'
import { useSetOrderFollowUpDateMutation } from 'store/services/labOrder'
import { useSendLabNoteMutation } from 'store/services/notes'
import { FollowUpNamespace, Order } from 'store/types'
import { FollowUpForm } from './FollowUpForm'

const formValidation = Yup.object().shape({
  followUpDate: Yup.date().required('Required'),
  notes: Yup.string(),
})

interface IProps {
  order: Order
}

export const FollowUpSection = ({ order }: IProps) => {
  const [setOrderFollowUpDate] = useSetOrderFollowUpDateMutation()
  const [sendLabNote] = useSendLabNoteMutation()

  const followUps = [...order?.followUpsList]?.sort((a, b) =>
    moment(b.addedAt).diff(moment(a.addedAt)),
  )

  const followUpDate = followUps?.[0]?.date ? moment(followUps?.[0]?.date).toDate() : ''

  return (
    <div className="row mt-2" css={{ background: '#F5F5F5' }}>
      <div className="col-lg-4 pt-3">
        <Formik
          enableReinitialize={true}
          validationSchema={formValidation}
          initialValues={{
            followUpDate,
            notes: '',
          }}
          onSubmit={async (values, { resetForm }) => {
            await Promise.all([
              setOrderFollowUpDate({
                orderId: Number(order.id),
                namespace: FollowUpNamespace.HST_PATIENT_CONSENT,
                date: moment(values.followUpDate).format('YYYY-MM-DD'),
                comment: values.notes,
              }).unwrap(),
              await sendLabNote({
                orderId: Number(order.id),
                text: `${values.notes} (follow-up: ${moment(values.followUpDate).format(
                  'MM/DD/YYYY',
                )})`,
                type: 'scheduling',
              }).unwrap(),
            ])
            resetForm()
          }}>
          {({ values, setFieldValue, errors, touched }) => (
            <FollowUpForm {...{ values, setFieldValue, errors, touched }} />
          )}
        </Formik>
      </div>
      <div className="col-lg-8 p-3">
        <div
          className="p-3"
          css={{ backgroundColor: '#00000005', maxHeight: 200, overflowY: 'auto' }}>
          <h4>Notes</h4>
          {followUps
            ?.filter((i) => i.comment)
            ?.map((f) => (
              <div
                key={f.id}
                className="p-2 mt-1"
                css={{ backgroundColor: '#C2C8CF99', borderRadius: 5 }}>
                <img src={PageIcon} className="mr-2" alt="icon" />
                <span className="mr-2" css={{ opacity: 0.5, fontWeight: '600' }}>
                  {moment(f.addedAt).format('MM/DD/YYYY hh:mm a')}
                </span>
                Note: {f.comment}. Follow-up Date: {moment(f.date).format('MM/DD/YYYY')}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
